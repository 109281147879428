@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.sideBarWrapper {
  width: 3.75rem;
}

.custom-shadow {
  box-shadow: 0px 0px 22px 1px rgb(173 173 173 / 22%);
}

.customHeight {
  height: calc(100vh - 7rem);
}

.custom-button-shadow:hover {
  box-shadow: inset 0 0 0 50px theme('colors.dangerColor');
  transition: all 1.3s;
}

.mainContentWrapper {
  width: 100%;
  height: 100vh;
}

.mainContent {
  background: #F2F7FB;
  width: 100%;
  height: max-content;
  padding: 1rem;
  border-top-left-radius: 4px;
  box-shadow: -5px -5px 5px -5px grey, 5px 5px 5px -5px grey;
}

.active {
  display: block;
  width: 15.3%;
}

.controlled {
  width: 85%;
}

.dropDown {
  font-size: 1.75rem;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

table th {
  padding: 0.6rem 0.75rem;
  background-color: #F6FAFD;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}

table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

table tr:nth-child(odd) {
  background-color: #F6FAFD;
}

table td {
  padding: 1.5rem 1rem;
  width: 20rem !important;
  text-align: left;
}

tbody td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

tbody td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ordered .item:nth-of-type(odd) {
  background-color: #F6FAFD;
}

.minHeight {
  min-height: 8rem;
}

.minCalcHeight {
  height: calc(100vh-300px);
}

.scrollBarWidth::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

.btn {
  background-image:
    linear-gradient(to right, theme('colors.successColor') 50%, theme('colors.textGray') 50%);

  background-size: 100% 200%;
  background-position: left;
  transition: background-position 0.5s ease-in-out;
}

.btn:hover {
  background-position: right;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10%;
  height: 50%;
  border-top-right-radius: 450%;
  background: linear-gradient(to right, theme('colors.primaryColor'), transparent);
  animation: gradientAnimation 5s infinite;
}

.gradient-overlay-modal {
  position: absolute;
  top: 23.7rem;
  right: 24.2rem;
  width: 7%;
  height: 30%;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top-left-radius: 2050%;
  border-bottom-left-radius: 150%;
  background: linear-gradient(to left, theme('colors.primaryColor'), transparent);
  /* Adjust colors as needed */
  animation: gradientAnimation 5s infinite;
}



:where(.css-dev-only-do-not-override-11xg00t).ant-picker-dropdown .ant-picker-content {
  width: inherit;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

.gradient-edit-modal {
  position: absolute;
  top: 8rem;
  left: 27.6rem;
  width: 17%;
  height: 10%;
  /* border-top-right-radius: 50%; */
  border-bottom-right-radius: 70%;
  background: linear-gradient(to top, theme('colors.primaryColor'), transparent);
  /* Adjust colors as needed */
  animation: gradientAnimation 5s infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}



.skeleton-screen {
  width: 75vw;
  height: 4rem;
  border-radius: 7px;
  background: linear-gradient(90deg,
      theme('colors.lightBlue') 100%,
      #fff 0%);

  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}


@media screen and (max-width:640px) {
  .gradient-overlay {
    position: absolute;
    top: 0;
    right: 1rem;
    width: 10%;
    height: 10%;
    border-radius: 50%;
    background: linear-gradient(to right, theme('colors.primaryColor'), transparent);
    animation: gradientAnimation 5s infinite;
  }

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  table th {
    padding: 0.3rem 0.45rem;
    background-color: #F6FAFD;
    text-align: left;
    font-size: 10px;
    font-weight: 600;
  }

  table td {
    padding: 0.75rem 0.5rem;
    width: 24rem !important;
    font-size: 10px;
    text-align: left;
  }

  .skeleton-screen {
    width: 100%;
    height: 2rem;
    border-radius: 7px;
    background: linear-gradient(90deg,
        theme('colors.lightBlue') 100%,
        #fff 0%);
  
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }

}